import type { NextPage } from "next"
import Link from "next/link"

import Header from "sections/Header"

const ErrorPage: NextPage = () => {
	return (
		<div className="w-full max-w-6xl mx-auto py-2 px-1">
			<Header />
			<section className="h-[80vh] w-full flex items-center justify-center">
				<div className="fixed flex items-center justify-center w-full h-full overflow-clip pointer-events-none">
					<h2 className="-z-10 text-[70vh] bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600 blur-2xl select-none">
						404
					</h2>
				</div>

				<div className="flex items-center justify-center w-full h-full pt-12">
					<span className="flex flex-row gap-1">
						<h2>Page not found.</h2>
						<Link href="/">
							<span className="font-medium underline cursor-pointer">
								Return home
							</span>
						</Link>
					</span>
				</div>
			</section>
		</div>
	)
}

export default ErrorPage
